import type { RpcMethodParameters } from '@scayle/storefront-nuxt'

type Options = Partial<{
  params: MaybeRefOrGetter<RpcMethodParameters<'getOEmbed'>>
  options?: Parameters<typeof useRpc<'getOEmbed'>>[3]
  key: string
}>

export async function useOEmbed({
  params,
  options,
  key = 'useOEmbed',
}: Options = {}) {
  const { data, fetching, fetch, error, status } = await useRpc(
    'getOEmbed',
    key,
    params,
    options,
  )

  return {
    data,
    fetching,
    fetch,
    error,
    status,
  }
}
